export function create_user_old(data) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/create_user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject();
        }
      });
  });
}

export function get_users() {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/get_users`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          reject();
        }
      });
  });
}

export function getSearchedUsers(query) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/search?query=${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // if (res.status === 200) {
        resolve(res.data);
        // } else {
        //   reject();
        // }
      });
  });
}

export function create_user(data) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 201) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function signin(data) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function verifyTokenValidity() {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/user/verifyTokenValidity`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return {};
        }
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
