import "./MessageStatus.css";
import MessageSentIcon from "../../assets/images/sent.png";
import MessageUnreadIcon from "../../assets/images/unread.png";
import MessageReadIcon from "../../assets/images/read.png";

function MessageStatus(props) {
  if (props.message.state === 1) {
    //Unread
    return (
      <span>
        <img
          className="message-status-icon"
          src={MessageUnreadIcon}
          alt="Unread"
        />
      </span>
    );
  } else if (props.message.state === 2) {
    //Read
    return (
      <span>
        <img className="message-status-icon" src={MessageReadIcon} alt="Read" />
      </span>
    );
  } else {
    //Sent
    return (
      <span>
        <img className="message-status-icon" src={MessageSentIcon} alt="Sent" />
      </span>
    );
  }
}

export default MessageStatus;
