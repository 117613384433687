import { useContext } from "react";
import { create_user } from "../../services/users";
import { LoaderContext } from "../../context/LoaderContext";
import { NotificationContext } from "../../context/NotificationContext";
import eyeGif from "../../assets/images/eye.gif";
import "./Signup.css";

function SignupComponent(props) {
  const { setLoading } = useContext(LoaderContext);
  const { notification, setNotification } = useContext(NotificationContext);

  function singup(event) {
    event.preventDefault();
    if (props.validateForm(0)) {
      const data = {
        name: event.target.fullName.value.trim(),
        email: event.target.email.value,
        password: event.target.password.value,
      };
      setLoading(true);
      create_user(data)
        .then((res) => {
          notification.push({
            id: new Date().getTime(),
            type: 1,
            title: "Success",
            description: res.message,
          });
          setNotification([...notification]);
          setLoading(false);
          props.isNewUser(false);
        })
        .catch((err) => {
          notification.push({
            id: new Date().getTime(),
            type: 0,
            title: "Error",
            description: err.message,
          });
          setNotification([...notification]);
          setLoading(false);
        });
    }
  }
  return (
    <div>
      <form onSubmit={(e) => singup(e)}>
        <div className="field full_name">
          <label htmlFor="fullName">Full Name</label>
          <input
            type="text"
            name="fullName"
            onChange={(e) => props.handleChange(e, 0)}
            noValidate
            autoComplete="off"
          />
          {props.fullNameError.length > 0 && (
            <span className="error">{props.fullNameError}</span>
          )}
        </div>
        <div className="field">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            onChange={(e) => props.handleChange(e, 0)}
            noValidate
            autoComplete="off"
          />
          {props.emailError.length > 0 && (
            <span className="error">{props.emailError}</span>
          )}
        </div>
        <div className="field">
          <label htmlFor="password">Password</label>
          <div className="password-field">
            <input
              type="password"
              name="password"
              onChange={(e) => props.handleChange(e, 0)}
              noValidate
              autoComplete="off"
              id="singup-password"
            />
            <span className="eye-icon">
              <img
                src={eyeGif}
                className="eye"
                onClick={() => props.togglePassword("singup-password")}
                alt="Eye"
              />
              <span className="eye-cross"></span>
            </span>
          </div>
          {props.passwordError.length > 0 && (
            <span className="error">{props.passwordError}</span>
          )}
        </div>
        <div className="info">
          <small>Password must be eight characters in length.</small>
        </div>
        <div className="submit">
          <button>Create</button>
        </div>
      </form>
      <p className="switch_form">
        Already a user?{" "}
        <button
          onClick={() => {
            props.isNewUser(false);
          }}
        >
          Sign in
        </button>
      </p>
    </div>
  );
}

export default SignupComponent;
