import React, { useContext } from "react";
import Search from "../../../../components/search/search";
import { UserContext } from "../../../../context/UserContext";
// import Header from "../../../../components/header/Header";
import { SidebarContext } from "../../../../context/SidebarContext";
import "./sidemenu.css";

function SideMenu(props) {
  const { user } = useContext(UserContext);
  const { sidebarCollapsed } = useContext(SidebarContext);

  return (
    <>
      {/* <Header
        sidebarCollapsed={props.sidebarCollapsed}
        toggleSideMenu={props.toggleSideMenu}
      /> */}
      <div
        className={
          sidebarCollapsed ? "sidemenu-container hide" : "sidemenu-container"
        }
      >
        <h2 className="user-name">{user ? user.name : ""}</h2>
        <Search />
      </div>
    </>
  );
}

export default SideMenu;
