import {
  FETCH_CONNECTIONS_REQUEST,
  FETCH_CONNECTIONS_SUCCESS,
  FETCH_CONNECTIONS_FAILURE,
  APPEND_UNREAD_MESSAGES,
  CLEAR_UNREAD_MESSAGES,
  UPDATE_TARGET,
  MOVE_CONNECTION_AT_TOP,
} from "./connectionTypes";

const initialState = {
  loading: false,
  connections: [],
  error: "",
  target: {},
  active_connection_id: null,
};

const connectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONNECTIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONNECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        connections: action.payload,
      };
    case FETCH_CONNECTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case APPEND_UNREAD_MESSAGES:
      const target_id = action.payload.messages[0].sender_id;
      const connections = state.connections;
      connections.forEach((connection) => {
        if (connection.target_id === target_id) {
          connection.count += action.payload.messages.length;
        }
      });

      return {
        ...state,
        connections: connections,
      };
    case CLEAR_UNREAD_MESSAGES:
      const connection_id = action.payload.connection_id;
      const current_connections = state.connections;
      current_connections.forEach((connection) => {
        if (connection.target_id === connection_id) {
          connection.count = 0;
        }
      });

      return {
        ...state,
        connections: current_connections,
      };
    case UPDATE_TARGET:
      return {
        ...state,
        target: action.payload.target,
        active_connection_id: action.payload.connection_id,
      };
    case MOVE_CONNECTION_AT_TOP: {
      const target_id = action.payload.target_id;
      const connections = state.connections;
      connections.sort((x, y) => {
        return x.target_id === target_id
          ? -1
          : y.target_id === target_id
          ? 1
          : 0;
      });
      return {
        ...state,
        connections: connections,
      };
    }
    default:
      return state;
  }
};

export default connectionReducer;
