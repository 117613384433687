import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import NotificationCount from "../notificationCount/NotificationCount";
import { DeleteDialogContext } from "../../context/DeleteDialogContext";
import { SidebarContext } from "../../context/SidebarContext";
import deleteIcon from "../../assets/images/trash.svg";
import "./connections.css";

function Connections(props) {
  const history = useHistory();
  const { updateDeleteConnection } = useContext(DeleteDialogContext);
  const { setSidebarCollapsed } = useContext(SidebarContext);

  useEffect(() => {
    props.getConnections();
  }, []);

  const switchChat = function (key) {
    if (window.innerWidth <= 659) {
      setSidebarCollapsed(true);
    }
    history.push(`/chat/${key}`);
  };

  const showDeleteConnectionDialog = (event, id, name) => {
    event.preventDefault();
    event.stopPropagation();
    updateDeleteConnection({ id, name });
  };

  const getConnections = function () {
    const connections = props.connections;
    let view = [];

    if (connections) {
      for (let i = 0; i < connections.length; i++) {
        view.push(
          <div
            className={
              props.connectionTarget._id === connections[i].target_id
                ? "connection-block selected"
                : "connection-block"
            }
            onClick={() => switchChat(connections[i].target_id)}
            key={i}
          >
            <p>{connections[i].name}</p>
            <div>
              <NotificationCount
                count={connections[i].count}
                showAlert={false}
              />
              <span
                className="delete-connection"
                onClick={(event) =>
                  showDeleteConnectionDialog(
                    event,
                    connections[i]._id,
                    connections[i].name
                  )
                }
                title="Delete Connection"
              >
                <img src={deleteIcon} height="15" alt="Delete Connection" />
              </span>
            </div>
          </div>
        );
      }
    }

    return view;
  };

  return <div className="connections-container">{getConnections()}</div>;
}

export default Connections;
