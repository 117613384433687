export function send_connection_request(data) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/notification/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        // if (response.status === 200) {
        resolve(response.data);
        // } else {
        //   reject();
        // }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fetch_connections() {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/connection/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return {}; //handle error
        }
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((response) => {
        reject(response.data);
      });
  });
}

export function send_connection_response(data) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/notification/response`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function delete_connection(data) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/connection/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject();
        }
      });
  });
}
