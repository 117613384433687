import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SideMenuIcon from "../../assets/images/menu-bar.svg";
import "./Header.css";
import LogoutComponent from "../logout/logout";
import { connect } from "react-redux";

function Header({ headerProps, messageState }) {
  const location = useLocation();
  const [currentTargetName, setCurrentTargetName] = useState();

  useEffect(() => {
    let receiver_id = window.location.pathname.split("/");
    if (receiver_id.length > 2 && receiver_id[1] === "chat") {
      receiver_id = receiver_id[2];
      setCurrentTargetName("Updating...");
    } else {
      setCurrentTargetName("");
    }
  }, [location]);

  useEffect(() => {
    if (messageState.lastAction === "LOADED") {
      setCurrentTargetName(messageState?.target?.name);
    }
    // setCurrentTargetName(messageState.lastAction || "NULL");
  }, [messageState]);

  return (
    <div className="header-container">
      <img
        src={SideMenuIcon}
        role="button"
        className="side-menu-btn"
        onClick={headerProps.toggleSideMenu}
        alt="Side menu"
      />
      <p className="header-text">{currentTargetName}</p>

      <LogoutComponent></LogoutComponent>
    </div>
  );
}

const mapStateToProps = (state, prevProps) => {
  return {
    messageState: state.message,
    headerProps: prevProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
