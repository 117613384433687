import { useContext } from "react";
import { signin } from "../../services/users";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { SocketContext } from "../../context/Socket";
import { LoaderContext } from "../../context/LoaderContext";
import { NotificationContext } from "../../context/NotificationContext";
import eyeGif from "../../assets/images/eye.gif";

function SigninComponent(props) {
  const { setUser } = useContext(UserContext);
  const { socket } = useContext(SocketContext);
  const { setLoading } = useContext(LoaderContext);
  const { notification, setNotification } = useContext(NotificationContext);
  const history = useHistory();

  function signin_user(event) {
    event.preventDefault();
    if (props.validateForm(1)) {
      const data = {
        email: event.target.email.value,
        password: event.target.password.value,
      };
      setLoading(true);
      signin(data)
        .then((res) => {
          notification.push({
            id: new Date().getTime(),
            type: 1,
            title: "Success",
            description: res.message,
          });
          setNotification([...notification]);
          localStorage.setItem("token", res.token);
          setUser({ _id: res.data.userId, name: res.data.name });
          setLoading(false);
          history.push("/home");
          socket.auth = { token: `Bearer ${res.token}` }; //setting auth token directly
          socket.close();
          socket.open();
        })
        .catch((err) => {
          notification.push({
            id: new Date().getTime(),
            type: 0,
            title: "Error",
            description: err.message,
          });
          setNotification([...notification]);
          setLoading(false);
        });
    }
  }

  return (
    <div>
      <form onSubmit={(e) => signin_user(e)}>
        <div className="field">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            onChange={(e) => props.handleChange(e, 1)}
            noValidate
            autoComplete="off"
          />
          {props.emailError.length > 0 && (
            <span className="error">{props.emailError}</span>
          )}
        </div>
        <div className="field">
          <label htmlFor="password">Password</label>
          <div className="password-field">
            <input
              type="password"
              name="password"
              onChange={(e) => props.handleChange(e, 1)}
              noValidate
              autoComplete="off"
              id="singin-password"
            />
            <span className="eye-icon">
              <img
                src={eyeGif}
                className="eye"
                onClick={() => props.togglePassword("singin-password")}
                alt="Eye"
              />
              <span className="eye-cross"></span>
            </span>
          </div>

          {props.passwordError.length > 0 && (
            <span className="error">{props.passwordError}</span>
          )}
        </div>
        <div className="submit">
          <button>Sign in</button>
        </div>
      </form>
      <p className="switch_form">
        New user?{" "}
        <button
          onClick={() => {
            props.isNewUser(true);
          }}
        >
          Sign up
        </button>
      </p>
    </div>
  );
}

export default SigninComponent;
