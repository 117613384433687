import { useState, useEffect } from "react";
import SuccessIcon from "../../assets/images/success.svg";
import ErrorIcon from "../../assets/images/error.svg";
import InfoIcon from "../../assets/images/info.svg";
import "./NotificationCard.css";

function NotificationCard(props) {
  const { notification } = props;
  const [list, setList] = useState(notification);

  useEffect(() => {
    const interval = setInterval(() => {
      if (list.length) {
        deleteNotification(list[0].id);
      }
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, [list]);

  useEffect(() => {
    setList(notification);
  }, [notification]);

  function deleteNotification(id) {
    const index = list.findIndex((e) => e.id === id);
    list.splice(index, 1);
    props.setNotification([...list]);
  }

  const notificationClass = (type) => {
    switch (type) {
      case 0:
        return "error";
      case 1:
        return "success";
      case 2:
        return "info";
      default:
        return "";
    }
  };

  const notificationIcon = (type) => {
    switch (type) {
      case 0:
        return ErrorIcon;
      case 1:
        return SuccessIcon;
      case 2:
        return InfoIcon;
      default:
        return "";
    }
  };

  return (
    <div className="notification-container">
      {list.map((notification, i) => (
        <div
          className={`notification-block ${notificationClass(
            notification.type
          )}`}
          id="notification-block"
          key={i}
        >
          <div className="icon">
            <img src={notificationIcon(notification.type)} alt="img" />
          </div>
          <div className="content">
            <h3>{notification?.title}</h3>
            <p>{notification?.description}</p>
          </div>
          <button onClick={() => deleteNotification(notification.id)}>X</button>
        </div>
      ))}
    </div>
  );
}

export default NotificationCard;
