import {
  APPEND_NEW_MESSAGE,
  FETCH_MESSAGES_FAILURE,
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  UPDATE_MESSAGE_STATE,
  UPDATE_MESSAAGE,
} from "./messageTypes";

import {
  LOADED,
  STATE_CHANGE,
  PAGINATION,
} from "../../container/home/sections/chatroom/chatTypes.js";

const initialState = {
  loading: false,
  messages: [],
  error: "",
  lastAction: null,
  target: {},
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
        lastAction: null,
      };
    case FETCH_MESSAGES_SUCCESS:
      let fetchedMessages = state.messages;
      let messagesUpdated = false;
      const target = action.payload.target;
      fetchedMessages.forEach((message) => {
        //TODO: may want to update according to pagination
        if (message.target_id === action.payload.target_id) {
          if (action.payload.page === 1) {
            message.messageArray = action.payload.messageArray;
          } else {
            message.messageArray = action.payload.messageArray.concat(
              message.messageArray
            );
          }
          messagesUpdated = true;
        }
      });

      if (!messagesUpdated) {
        action.payload.lastAction =
          // action.payload.page === 1 ? LOADED : PAGINATION;
          fetchedMessages.push(action.payload);
      }

      return {
        ...state,
        loading: false,
        messages: fetchedMessages,
        lastAction: action.payload.page === 1 ? LOADED : PAGINATION,
        target: target,
      };
    case FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        lastAction: null,
      };
    case APPEND_NEW_MESSAGE:
      let messages = state.messages;
      let messageAdded = false;
      if (messages.length > 0) {
        for (let message of messages) {
          if (message.target_id === action.payload.target_id) {
            //if this exists then messages must exist as well
            message.messageArray = message.messageArray.concat(
              action.payload.messages
            );

            messageAdded = true;
            break;
          }
        }

        if (!messageAdded) {
          //if no entry with target_id exists
          messages.push({
            target_id: action.payload.target_id,
            messageArray: action.payload.messages,
          });
        }
      } else {
        messages.push({
          target_id: action.payload.target_id,
          messageArray: action.payload.messages,
        });
      }

      return {
        ...state,
        messages: messages,
        lastAction: action.payload.action_type,
        loading: false,
      };
    case UPDATE_MESSAGE_STATE:
      let messagesToBeUpdated = state.messages;
      if (messagesToBeUpdated.length > 0) {
        for (let message of messagesToBeUpdated) {
          if (message.target_id === action.payload.target_id) {
            //chat to be updated
            let currentMessages = message.messageArray;
            const messageLength = currentMessages.length;
            for (let i = messageLength - 1; i >= 0; i--) {
              const messageCreationDateTime = new Date(
                currentMessages[i].created_at
              ).toUTCString();
              const payloadMessageCreationDateTime = action?.payload?.message
                ?.created_at
                ? new Date(action.payload.message.created_at).toUTCString()
                : new Date().toUTCString();

              if (
                (messageCreationDateTime === payloadMessageCreationDateTime ||
                  messageCreationDateTime < payloadMessageCreationDateTime) &&
                currentMessages[i].state < action.payload.message.state
              ) {
                currentMessages[i].state = action.payload.message.state;
              }
            }

            message.messageArray = currentMessages;
            break;
          }
        }
      }

      return {
        ...state,
        messages: messagesToBeUpdated,
        lastAction: STATE_CHANGE,
        loading: false,
      };
    case UPDATE_MESSAAGE:
      let conversations = state.messages;
      for (let message of conversations) {
        if (message.target_id === action.payload.target_id) {
          let currentMessages = message.messageArray;
          const messageLength = currentMessages.length;

          for (let i = messageLength - 1; i >= 0; i--) {
            if (
              currentMessages[i]._id === action.payload.message_id ||
              currentMessages[i].unique_id === action.payload.unique_id
            ) {
              if (action.payload.type === 0) {
                currentMessages[i].title = action.payload.title;
                currentMessages[i].is_deleted = 1;
              } else if (action.payload.type === 1) {
                currentMessages[i].title = action.payload.title;
                currentMessages[i].is_edited = 1;
              }
              break;
            }
          }
          message.messageArray = currentMessages;
          break;
        }
      }

      return {
        ...state,
        messages: conversations,
        lastAction: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default messageReducer;
