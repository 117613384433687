import React, { useState, useRef, useEffect } from "react";
import { getSearchedUsers } from "../../services/users";
import SearchList from "../searchList/SearchList";
import Connections from "../connections/connections";
import Notifications from "../notifications/Notifications";
import NotificationIcon from "../notificationIcon/NotificationIcon";
import { fetchNotifications } from "../../redux";
import "./search.css";
import { connect } from "react-redux";
import { fetchConnections, updateCount } from "../../redux";

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function Search({
  notificationState,
  connectionState,
  fetchNotifications,
  fetchConnections,
  updateCount,
}) {
  let [sideViewState, setSideViewState] = useState(0); // 0 - connections, 1 - search results, 2 - notifications
  let delayedQuery = debounce((q) => getSearchedData(q), 200);
  const inputRef = useRef(null);
  const [searchedUsers, setSearchedUsers] = useState([]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  function onInputChange(e) {
    delayedQuery(e.target.value);
  }

  function getConnections() {
    fetchConnections();
    setSideViewState(0);
  }

  function getNotifications(manualHit) {
    fetchNotifications(manualHit);
    setSideViewState(2);
  }

  function getSearchedData(q) {
    if (q === "") {
      setSideViewState(0); //get connections at this point
      return;
    }

    getSearchedUsers(q)
      .then((res) => {
        if (res) {
          setSideViewState(1);
          setSearchedUsers(res);
        }
      })
      .catch((err) => {
        // will make a custom toastr for it
        // setLoading(false);
      });
  }

  function listView() {
    if (sideViewState === 0) {
      return (
        <Connections
          connections={connectionState.connections}
          getConnections={getConnections}
          connectionTarget={connectionState.target}
        />
      );
    } else if (sideViewState === 1) {
      return (
        <SearchList
          searchedUsers={searchedUsers}
          getSearchedData={getSearchedData}
          inputRef={inputRef}
        />
      );
    } else {
      return (
        <Notifications
          notifications={notificationState.notifications}
          getNotifications={getNotifications}
          getConnections={getConnections}
        />
      );
    }
  }

  return (
    <div className="search-container">
      <div className="search-actions">
        <input
          className="search"
          onChange={onInputChange}
          placeholder="Search"
          ref={inputRef}
          type="search"
        />
        <NotificationIcon
          getNotifications={getNotifications}
          notificationState={notificationState}
          updateCount={updateCount}
        />
      </div>

      {listView()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    notificationState: state.notification,
    connectionState: state.connection,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNotifications: (manualHit = false) =>
      dispatch(fetchNotifications(manualHit)),
    fetchConnections: () => dispatch(fetchConnections()),
    updateCount: (count) => dispatch(updateCount(count)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

// export default Search;
