import React from "react";
import socketio from "socket.io-client";

const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const token = localStorage.getItem("token");

export const socket = socketio(ENDPOINT, {
  forceNew: true,
  auth: {
    token: `Bearer ${token}`,
  },
});

export const SocketContext = React.createContext();
