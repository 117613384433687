import { useContext } from "react";
import { send_connection_response } from "../../services/connections";
import { LoaderContext } from "../../context/LoaderContext";
import "./Notifications.css";

function Notifications(props) {
  const { setLoading } = useContext(LoaderContext);

  function getNotificationTitle(notification) {
    if (parseInt(notification.type) === 0) {
      return `${notification.name} has sent you a connection request`;
    } else if (parseInt(notification.type) === 1) {
      return `Your connection request to ${notification.name} has been rejected`;
    } else if (parseInt(notification.type) === 2) {
      return `Your connection request to ${notification.name} has been accepted`;
    } else {
      return `Something went wrong`;
    }
  }

  function handleConnectionRequest(response, initiator_id, notification_id) {
    setLoading(true);
    const data = {
      id: notification_id,
      initiator_id: initiator_id,
      response: response,
      type: response === 0 ? 1 : 2,
    };
    send_connection_response(data)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          props.getNotifications();
        }
      })
      .catch((res) => {
        setLoading(false);
      });
  }

  function showNotifications() {
    let view = [];
    view.push(
      <div key={-2} className="notification-title">
        <span>Notifications</span>
        <button onClick={props.getConnections}>X</button>
      </div>
    );
    if (props.notifications && props.notifications.length > 0) {
      for (let i = 0; i < props.notifications.length; i++) {
        view.push(
          <div className="notification-card" key={i}>
            <div className="notification-text">
              {getNotificationTitle(props.notifications[i])}
            </div>
            {props.notifications[i].type === 0 ? (
              <>
                <button
                  className="button accept"
                  onClick={() =>
                    handleConnectionRequest(
                      1,
                      props.notifications[i].sender_id,
                      props.notifications[i]._id
                    )
                  }
                >
                  Accept
                </button>
                <button
                  className="button reject"
                  onClick={() =>
                    handleConnectionRequest(
                      0,
                      props.notifications[i].sender_id,
                      props.notifications[i]._id
                    )
                  }
                >
                  Reject
                </button>
              </>
            ) : null}
          </div>
        );
      }
    } else {
      view.push(<div key={-1}>No new notifications</div>);
    }
    return view;
  }

  return <div className="notifications-container">{showNotifications()}</div>;
}

export default Notifications;
