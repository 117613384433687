import { useContext, useState, useRef, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import MessageStatus from "../MessageStatus/MessageStatus";
import "./MessageList.css";
import { getDateElement } from "./DateActions.js";
import threeDots from "../../assets/images/3-dots.png";
import MessageActions from "../messageActions/MessageActions";

function MessageList(props) {
  const { user } = useContext(UserContext);
  const [hoveredMessage, updateHoveredMessge] = useState(null);
  const messageActionRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        messageActionRef.current &&
        !messageActionRef.current.contains(event.target)
      ) {
        updateHoveredMessge(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [messageActionRef]);

  const showHoverActions = (unique_id) => {
    updateHoveredMessge(unique_id);
  };

  function appendMessagesToView(messages) {
    let view = [];
    let currentMessages = [];
    messages.forEach((message) => {
      if (message?.target_id === props.receiverId) {
        currentMessages = message.messageArray;
      }
    });

    const topElement = <span key={1} ref={props.topEleRef}></span>;
    view.push(topElement);

    let prevState = null;
    let prevSender = null;
    let currentState = null;
    let currentSender = null;
    let prevMessageDate = null;
    let currentMessageDate = null;
    const totalMessages = currentMessages.length;
    const today = new Date();
    if (currentMessages && totalMessages > 0) {
      props.firstMessageRef.current = currentMessages[0]._id;

      const time = new Date(currentMessages[0].created_at);
      const dateStr = getDateElement(time, today);
      view.push(
        <div className="date-time" key={dateStr}>
          <span>{dateStr}</span>
        </div>
      );

      currentMessages.forEach((message, index) => {
        const time = new Date(message.created_at);
        const created_at = time.getFullTime();
        currentMessageDate = time;
        currentState = message.state;
        currentSender = message.sender_id;
        if (
          currentState === 1 &&
          ((prevState === 2 && prevSender !== user._id) ||
            prevSender === user._id) &&
          currentSender !== user._id
        ) {
          view.push(
            <div
              className="unread-messages"
              ref={props.unreadEleRef}
              key="unread-messages"
            >
              <span>{totalMessages - index} Unread Messages</span>
            </div>
          );
        }

        if (
          prevMessageDate &&
          checkForDifferentDates(prevMessageDate, currentMessageDate)
        ) {
          const dateStr = getDateElement(currentMessageDate, today);
          view.push(
            <div className="date-time" key={dateStr}>
              <span>{dateStr}</span>
            </div>
          );
        }
        view.push(
          <div
            className={
              message.sender_id === user._id
                ? "right-align message" +
                  (index === totalMessages - 1 ? " last-message" : "")
                : "left-align message"
            }
            key={message.created_at}
          >
            <span className={message.is_deleted === 1 ? "italic-font" : ""}>
              <p>
                {message.title}
                {message.is_edited === 1 && message.is_deleted !== 1 ? (
                  <label className="edited-message">(edited)</label>
                ) : (
                  ""
                )}
              </p>
              <span className="bottom-right">
                <label className="created-at">{created_at}</label>
                {message.sender_id === user._id ? (
                  <MessageStatus message={message}></MessageStatus>
                ) : (
                  ""
                )}
              </span>
            </span>
            {message.is_deleted === 1 ||
            message.sender_id !== user._id ? null : (
              <div
                className="hover-container"
                onClick={() => showHoverActions(message.unique_id)}
              >
                <img
                  src={threeDots}
                  height="5"
                  className={
                    hoveredMessage === message.unique_id ? "visible" : ""
                  }
                  alt="Edit Message"
                />

                {hoveredMessage === message.unique_id ? (
                  <div ref={messageActionRef}>
                    <MessageActions
                      unique_id={message.unique_id}
                      is_edited={message.is_edited}
                      is_deleted={message.is_deleted}
                      receiver_id={props.receiverId}
                      title={message.title}
                      updateHoveredMessge={updateHoveredMessge}
                      messageInputField={props.messageInputField}
                      deleteMessage={props.deleteMessage}
                      updateIsEditingMessage={props.updateIsEditingMessage}
                    ></MessageActions>
                  </div>
                ) : null}
              </div>
            )}
          </div>
        );
        prevState = currentState;
        prevSender = currentSender;
        prevMessageDate = currentMessageDate;
      });
    }

    const bottomElement = <span key={-1} ref={props.bottomEleRef}></span>;
    view.push(bottomElement);
    return view;
  }

  return (
    <div
      className="message-list-container"
      id="message-list-container"
      ref={props.MessageListRef}
    >
      {appendMessagesToView(props.messages)}
    </div>
  );
}

export default MessageList;

Date.prototype.getFullTime = function () {
  const hour = this.getHours();
  const hourPrefix = hour < 10 ? "0" : "";
  const minute = this.getMinutes();
  const minutePrefix = minute < 10 ? "0" : "";
  return `${hourPrefix + hour}:${minutePrefix + minute}`;
};

function checkForDifferentDates(prev, current) {
  return (
    prev.getFullYear() < current.getFullYear() ||
    prev.getMonth() < current.getMonth() ||
    prev.getDate() < current.getDate()
  );
}
