import {
  FETCH_NOTIFICATION_FAILURE,
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  UPDATE_COUNT,
} from "./notificationTypes";

const initialState = {
  loading: false,
  notifications: [],
  error: "",
  unread_count: 0,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload.notifications,
        unread_count: action.payload.unread_count,
      };
    case FETCH_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case UPDATE_COUNT:
      return {
        ...state,
        unread_count: action.payload.count,
      };
    default:
      return state;
  }
};

export default notificationReducer;
