import { useContext, useEffect } from "react";
import { SocketContext } from "../../context/Socket";
import NotificationCount from "../notificationCount/NotificationCount";
import bellIcon from "../../assets/images/new_bell.png";
import { NotificationContext } from "../../context/NotificationContext";

function NotificationIcon(props) {
  const { socket } = useContext(SocketContext);
  const { notification, setNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (socket) {
      socket.on("NEW_NOTIFICATION", (res) => {
        notification.push({
          id: new Date().getTime(),
          type: 2,
          title: res.title,
          description: res.message,
        });
        setNotification([...notification]);
        const currentNotifictionCount = props.notificationState.unread_count;
        props.updateCount(currentNotifictionCount + 1);
      });
    }

    return () => {
      socket.off("NEW_NOTIFICATION");
    };
  }, [notification]);

  function getNotifications() {
    props.updateCount(0);
    props.getNotifications(true);
  }

  return (
    <span onClick={getNotifications} className="bell-icon-container">
      <img
        src={bellIcon}
        height="20"
        width="20"
        title="Notifications"
        alt="Notifications"
      />
      <NotificationCount
        count={props.notificationState.unread_count}
        showAlert={true}
      />
    </span>
  );
}

export default NotificationIcon;
