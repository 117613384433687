import React, { useEffect, useContext, useState, useRef } from "react";
import MessageList from "../../../../components/messageList/MessageList";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import { SocketContext } from "../../../../context/Socket";
import "./chatroom.css";
import sendMessage from "../../../../assets/images/send-message.png";
import tickImage from "../../../../assets/images/checkmark.svg";
import crossImage from "../../../../assets/images/close.svg";
import {
  appendNewMessage,
  appendUnreadMessages,
  clearUnreadMessages,
  fetchMessages,
  updateMessageState,
  updateMessage,
  moveConnectionAtTop,
} from "../../../../redux";
import { connect } from "react-redux";
import {
  SENT,
  LOADED,
  APPENDED,
  PAGINATION,
  STATE_CHANGE,
} from "./chatTypes.js";

import {
  messagesLoaded,
  messageSent,
  messagesAppended,
} from "./chatActions.js";
import ReceivedMessagesBadge from "../../../../components/ReceivedMessagesBadge/ReceivedMessagesBadge";
import HomeBanner from "./homeBanner.js";

function ChatRoom({
  chatRoomProps,
  messageState,
  connectionState,
  fetchMessages,
  appendNewMessage,
  updateMessageState,
  appendUnreadMessages,
  clearUnreadMessages,
  updateMessage,
  moveConnectionAtTop,
}) {
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { socket } = useContext(SocketContext);
  const bottomEleRef = useRef();
  const MessageListRef = useRef();
  const unreadEleRef = useRef();
  const [receiverId, setReceiverId] = useState(null);
  const [page, setPage] = useState(1);
  const messagePipeline = useRef([]);
  const [receivedMessageBadgeCount, updateReceivedMessageBadgeCount] =
    useState(0);
  const firstMessageRef = useRef();
  const messageInputField = useRef();
  const sendMessageButton = useRef();
  const [isEditingMessage, updateIsEditingMessage] = useState(false);

  useEffect(() => {
    const receiver_id = getCurrentChatUserId();
    if (receiver_id) {
      setReceiverId(receiver_id);
      clearUnreadMessages(receiver_id);
      fetchMessages(receiver_id, 1, null);
    } else {
      setReceiverId(null);
    }
    updateReceivedMessageBadgeCount(0);
  }, [location]);

  useEffect(() => {
    switch (messageState.lastAction) {
      case LOADED: {
        if (unreadEleRef.current) {
          messagesLoaded(unreadEleRef.current, 1);
        } else {
          messagesLoaded(bottomEleRef.current, 0);
        }

        if (receiverId) {
          sendMessageStatusUpdate(null, 2, receiverId);
        }
        break;
      }
      case SENT: {
        messageSent(bottomEleRef.current);
        break;
      }
      case APPENDED: {
        messagesAppended(MessageListRef.current, bottomEleRef.current);
        break;
      }
      case PAGINATION: {
        // MessageListRef.current.scrollTop =
        //   MessageListRef.current.scrollHeight -
        //   MessageListRef.current.dataset.prevHeight;
        break;
      }
      case STATE_CHANGE: {
        break;
      }
      default: {
      }
    }
  }, [messageState]);

  useEffect(() => {
    if (socket) {
      socket.on("MESSAGE_RECEIVED", (res) => {
        handleReceivedMessage(res);
      });

      socket.on("MESSAGE_UPDATED", (res) => {
        handleUpdatedMessage(res);
      });

      if (MessageListRef.current) {
        MessageListRef.current.addEventListener("scroll", handleScrollThrottle);
      }
    }

    return () => {
      socket.off("MESSAGE_RECEIVED");
      socket.off("MESSAGE_UPDATED");
      if (MessageListRef.current) {
        MessageListRef.current.removeEventListener(
          "scroll",
          handleScrollThrottle
        );
      }
    };
  }, [user, receiverId]);

  useEffect(() => {
    const receiver_id = getCurrentChatUserId();
    if (receiver_id && page > 1) {
      const pivot_message_id = firstMessageRef.current
        ? firstMessageRef.current
        : null;

      fetchMessages(receiver_id, page, pivot_message_id);
      // MessageListRef.current.dataset.prevHeight =
      //   MessageListRef.current.scrollHeight;

      MessageListRef.current.scrollTop = 1; //workarond so cursor doesn't stay attched to top on pagination
    }
  }, [page]);

  const handleScrollThrottle = () => {
    throttleFunction(handleScroll, 1000);
  };

  const handleScroll = function () {
    if (MessageListRef.current.scrollTop === 0) {
      setPage((prevPage) => {
        return prevPage + 1;
      });
    } else {
      if (MessageListRef.current) {
        const scrollBottomDistance =
          MessageListRef.current.scrollHeight -
          MessageListRef.current.scrollTop -
          MessageListRef.current.clientHeight;
        if (scrollBottomDistance <= 80) {
          updateReceivedMessageBadgeCount(0);
        }
      }
    }
  };

  function formSubmit(e) {
    e.preventDefault();
    messageInputField.current.focus();
    const inputTarget = e.target.message;
    const title = inputTarget.value;
    if (title) {
      let receiver_id = window.location.pathname.split("/");
      receiver_id = receiver_id[2];
      const messageData = {
        connection_id: connectionState.active_connection_id,
        sender_id: user._id,
        receiver_id: receiver_id,
        title: title,
        created_at: new Date().getTime(),
        state: 0,
        unique_id: createMessageUniqueId(user._id),
      };

      pushMessageToPipeline(messageData);
      appendNewMessage([messageData], receiver_id, SENT);
      inputTarget.value = "";
    }
  }

  function handleReceivedMessage(res) {
    const currentUserId = getCurrentChatUserId();

    //Incase of receiver only send the lastest message for update
    if (res[0].sender_id === user._id) {
      if (res[0].state === 0) {
        //case for mulitiple logins
        appendNewMessage(res, res[0].receiver_id, APPENDED); //all messages will be sent out in case of append
        if (res[0].receiver_id === currentUserId) {
          updateReceivedMessageBadge(res.length);
        }
      } else {
        updateMessageState(res[0], res[0].receiver_id); //only last message will be sent for state update
      }
    } else if (res[0].sender_id === currentUserId) {
      //User has received messages and sender's chat is open
      sendMessageStatusUpdate(res[0]._id, 2, null); //Read
      updateMessageStateForReceiverLocally(res);
      appendNewMessage(res, res[0].sender_id, APPENDED);
      // sendMessageStatusUpdate(res[0]._id, 2, null); //Read
      updateReceivedMessageBadge(res.length);
    } else {
      //User has received messages and sender's chat is not open
      sendMessageStatusUpdate(res[0]._id, 1, null); //Unread
      // updateUnreadMessageCount(res[0].sender_id, "NEW_MESSAGE");
      appendUnreadMessages(res);
      moveConnectionAtTop(res[0].sender_id);
    }
  }

  function handleUpdatedMessage(res) {
    if (
      (res.sender_id === user._id && res.receiver_id === receiverId) ||
      (res.sender_id === receiverId && res.receiver_id === user._id)
    ) {
      updateMessage(res._id, res.unique_id, receiverId, res.type, res.title);
    }
  }

  function getCurrentChatUserId() {
    const pathnames = window.location.pathname.split("/");
    if (pathnames.length > 2 && pathnames[1] === "chat") {
      return pathnames[2];
    }
    return null;
  }

  function sendMessageStatusUpdate(message_id, state, target_id) {
    socket.emit("UPDATE_MESSAGE_STATE", {
      message_id: message_id,
      target_id: target_id,
      state: state,
    });
  }

  const pushMessageToPipeline = (message) => {
    messagePipeline.current.push(message);
    throttleFunction(sendMessageAsync, 500);
  };

  function sendMessageAsync() {
    let sendCount = 0;
    if (messagePipeline.current.length > 5) {
      sendCount = 5;
    } else {
      sendCount = messagePipeline.current.length;
    }

    if (sendCount > 0) {
      let messages = messagePipeline.current.splice(0, sendCount);
      socket.emit("SEND_MESSAGE", messages);
      setTimeout(() => {
        throttleFunction(sendMessageAsync, 500); //trigerring throttle for next batch
      }, 100);
    }
  }

  function updateReceivedMessageBadge(count) {
    const scrollHeight = MessageListRef.current.scrollHeight;
    const scrollPosition =
      MessageListRef.current.scrollTop + MessageListRef.current.clientHeight;
    const scrollBottomDistance = scrollHeight - scrollPosition;

    if (scrollBottomDistance > 80) {
      updateReceivedMessageBadgeCount((prevValue) => {
        return prevValue + count;
      });
    } else {
      updateReceivedMessageBadgeCount(0);
    }
  }

  function updateMessageStateForReceiverLocally(messages) {
    if (messages && messages.length > 0) {
      messages.map((message) => {
        message.state = 2;
      });
    }
  }

  let timerId;
  const throttleFunction = function (func, delay) {
    if (timerId) {
      return;
    }

    timerId = setTimeout(function () {
      func();
      timerId = undefined;
    }, delay);
  };

  const sendMessageUpdate = (data) => {
    socket.emit("SEND_MESSAGE_UPDATE", data);
  };

  const deleteMessage = (unique_id, receiver_id) => {
    const data = { unique_id: unique_id, type: 0 };
    sendMessageUpdate(data);
    updateMessage(null, unique_id, receiver_id, 0, "MESSAGE DELETED");
  };

  const sendEditMessage = (event, edit = false) => {
    event.stopPropagation();
    const inputField = messageInputField.current;

    if (edit) {
      const title = inputField.value;
      const unique_id = inputField.dataset.unique_id;
      const receiver_id = inputField.dataset.receiver_id;
      const data = {
        unique_id: unique_id,
        type: 1,
        title: title,
      };
      sendMessageUpdate(data);
      updateMessage(null, unique_id, receiver_id, 1, title);
    }
    inputField.dataset.unique_id = null;
    inputField.dataset.receiver_id = null;
    inputField.value = "";
    updateIsEditingMessage(false);
  };

  return (
    <div className={"chatroom-container" + (receiverId ? " chat-screen" : "")}>
      {receiverId ? (
        <form onSubmit={formSubmit}>
          <MessageList
            messages={messageState.messages}
            bottomEleRef={bottomEleRef}
            MessageListRef={MessageListRef}
            unreadEleRef={unreadEleRef}
            receiverId={receiverId}
            firstMessageRef={firstMessageRef}
            messageInputField={messageInputField}
            deleteMessage={deleteMessage}
            updateIsEditingMessage={updateIsEditingMessage}
          />
          <div className="chat-input">
            <input
              autoComplete="off"
              name="message"
              placeholder="Type your message here"
              ref={messageInputField}
            />
            {isEditingMessage ? (
              <img
                src={sendMessage}
                className="send-message disable"
                alt="Send Message"
              />
            ) : (
              <input
                type="image"
                src={sendMessage}
                className="send-message"
                alt="Send Message"
                title="Send Message"
                ref={sendMessageButton}
              />
            )}
            <span
              className={
                isEditingMessage
                  ? "edit-message-actions show"
                  : "edit-message-actions"
              }
            >
              <button
                type={isEditingMessage ? "submit" : "button"}
                onClick={(event) => sendEditMessage(event, true)}
              >
                <img src={tickImage} alt="Confirm Edit" />
              </button>
              <button onClick={(event) => sendEditMessage(event, false)}>
                <img src={crossImage} alt="Cancel Edit" />
              </button>
            </span>
          </div>
          <ReceivedMessagesBadge
            count={receivedMessageBadgeCount}
            bottomEleRef={bottomEleRef}
            updateReceivedMessageBadgeCount={updateReceivedMessageBadgeCount}
          ></ReceivedMessagesBadge>
        </form>
      ) : (
        <HomeBanner />
      )}
    </div>
  );
}

const mapStateToProps = (state, prevProps) => {
  return {
    messageState: state.message,
    connectionState: state.connection,
    chatRoomProps: prevProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMessages: (target_id, page, messageId) =>
      dispatch(fetchMessages(target_id, page, messageId)),
    appendNewMessage: (message, target_id, action_type) =>
      dispatch(appendNewMessage(message, target_id, action_type)),
    updateMessageState: (message, target_id) =>
      dispatch(updateMessageState(message, target_id)),
    appendUnreadMessages: (messages) =>
      dispatch(appendUnreadMessages(messages)),
    clearUnreadMessages: (connection_id) =>
      dispatch(clearUnreadMessages(connection_id)),
    updateMessage: (message_id, unique_id, target_id, type, title) =>
      dispatch(updateMessage(message_id, unique_id, target_id, type, title)),
    moveConnectionAtTop: (target_id) =>
      dispatch(moveConnectionAtTop(target_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoom);

const createMessageUniqueId = (person_id) => {
  return `mes,${person_id},${new Date().getTime()}`;
};
