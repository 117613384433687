import "./MessageActions.css";

function MessageActions(props) {
  const inputField = props.messageInputField.current;

  const editMessage = (event, title) => {
    event.stopPropagation();
    inputField.value = title;
    inputField.dataset.unique_id = props.unique_id;
    inputField.dataset.receiver_id = props.receiver_id;
    inputField.focus();
    props.updateHoveredMessge(null);
    props.updateIsEditingMessage(true);
  };

  return (
    <div className="message-actions">
      {props.is_deleted === 1 ? null : (
        <>
          <button onClick={(event) => editMessage(event, props.title)}>
            Edit Message
          </button>
          <button
            onClick={() =>
              props.deleteMessage(props.unique_id, props.receiver_id)
            }
          >
            Delete Message
          </button>
        </>
      )}
    </div>
  );
}

export default MessageActions;
