import homeBannerPNG from "../../../../assets/images/home-banner.png";

export default function HomeBanner() {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <h2>Welcome to ConnectX</h2>
        <p>Search and start sending connection requests to your friends.</p>
      </div>
      <div className="banner-image">
        <img src={homeBannerPNG} />
        <a href="https://storyset.com/social-media" target="_blank">
          Social media illustrations by Storyset
        </a>
      </div>
    </div>
  );
}
