import React, { useState } from "react";
import SideMenu from "./sections/sidemenu/sidemenu";
import ChatRoom from "./sections/chatroom/chatroom";
import { Provider } from "react-redux";
import store from "../../redux/store";
import { DeleteDialogContext } from "../../context/DeleteDialogContext";
import { SidebarContext } from "../../context/SidebarContext";
import DeleteConnectionDialog from "../../components/DeleteConnectionDialog/DeleteConnectionDialog";
import Header from "../../components/header/Header";
import "./home.css";

function Home(props) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    window.innerWidth <= 659
  );
  const [deleteConnection, updateDeleteConnection] = useState(null);

  function toggleSideMenu() {
    setSidebarCollapsed((visible) => {
      return !visible;
    });
  }

  return (
    <Provider store={store}>
      <SidebarContext.Provider
        value={{ sidebarCollapsed, setSidebarCollapsed }}
      >
        <DeleteDialogContext.Provider
          value={{ deleteConnection, updateDeleteConnection }}
        >
          <SideMenu toggleSideMenu={toggleSideMenu} />
          <div
            className={
              sidebarCollapsed ? "main-container expand" : "main-container"
            }
          >
            <Header toggleSideMenu={toggleSideMenu} />
            <ChatRoom />
          </div>
          <div
            className={!sidebarCollapsed ? "back-drop show" : ""}
            onClick={toggleSideMenu}
          />
          {deleteConnection ? (
            <DeleteConnectionDialog
              deleteConnection={deleteConnection}
              updateDeleteConnection={updateDeleteConnection}
            />
          ) : null}
        </DeleteDialogContext.Provider>
      </SidebarContext.Provider>
    </Provider>
  );
}

export default Home;
