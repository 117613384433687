import React, { useState } from "react";
import ConnectIcon from "../../assets/images/logo.png";
import SignupComponent from "../../components/signup/Signup";
import SigninComponent from "../../components/signin/Signin";
import "./welcome.css";

function Welcome(props) {
  const [newUser, isNewUser] = useState(false);
  const [fullNameError, setFullNameError] = useState(" ");
  const [emailError, setEmailError] = useState(" ");
  const [passwordError, setPasswordError] = useState(" ");

  const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );

  // let isSubmitted = false;

  function handleChange(event, type) {
    //0: Sign up, 1: Sign in
    event.preventDefault();
    const { name, value } = event.target;
    let currentErrors = {
      email: emailError,
      password: passwordError,
    };

    if (type === 0) {
      currentErrors["fullName"] = fullNameError;
    }

    switch (name) {
      case "fullName":
        currentErrors.fullName =
          value.length < 3 ? "Full Name must be 3 characters long!" : "";
        break;
      case "email":
        currentErrors.email = validEmailRegex.test(value)
          ? ""
          : "Email is not valid!";
        break;
      case "password":
        currentErrors.password =
          value.length < 8 ? "Password must be 8 characters long!" : "";
        break;
      default:
        break;
    }

    setEmailError(currentErrors.email);
    setPasswordError(currentErrors.password);
    if (type === 0) {
      setFullNameError(currentErrors.fullName);
    }
  }

  function validateForm(type) {
    let valid = true;

    if (emailError || passwordError || (type === 0 && fullNameError)) {
      valid = false;
    }
    return valid;
  }

  function togglePassword(id) {
    const passwordField = document.getElementById(id);
    if (passwordField.type === "password") {
      passwordField.type = "text";
    } else {
      passwordField.type = "password";
    }
  }

  return (
    <div className="welcome-container">
      <div className="welcome-block">
        <img className="connect-icon" src={ConnectIcon} alt="Connect" />
        <p className="welcome">
          Welcome to ConnectX, Please enter your details to continue
        </p>
        <p className="policy">
          (ConnectX provides an environment where people can connect with one
          another. ConnectX will not be responisble for content created on it.)
        </p>
        {newUser ? (
          <SignupComponent
            isNewUser={isNewUser}
            fullNameError={fullNameError}
            emailError={emailError}
            passwordError={passwordError}
            handleChange={handleChange}
            validateForm={validateForm}
            togglePassword={togglePassword}
          ></SignupComponent>
        ) : (
          <SigninComponent
            isNewUser={isNewUser}
            emailError={emailError}
            passwordError={passwordError}
            handleChange={handleChange}
            validateForm={validateForm}
            togglePassword={togglePassword}
          ></SigninComponent>
        )}
      </div>
    </div>
  );
}

export default Welcome;
