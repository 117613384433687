import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { SocketContext } from "../../context/Socket";
import LogoutIcon from "../../assets/images/log-out.svg";
import "./logout.css";

function LogoutComponent() {
  const history = useHistory();
  const { socket } = useContext(SocketContext);

  function logout() {
    localStorage.setItem("token", "");
    localStorage.setItem("name", "");
    history.replace("/");
    socket.disconnect();
  }

  return (
    <div className="logout-block">
      <img
        src={LogoutIcon}
        onClick={logout}
        height="20"
        title="Logout"
        alt="Logout"
      />
    </div>
  );
}

export default LogoutComponent;
