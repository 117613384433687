export const getDateElement = (date, today) => {
  const difference = today.getDate() - date.getDate();
  switch (difference) {
    case 0:
      return "Today";
    case 1:
      return "Yesterday";
    default:
      return `${date.getDate()} ${date.toLocaleString("default", {
        month: "long",
      })} ${date.getFullYear()}`;
  }
};
