import "./loader.css";

function Loader(props) {
  return (
    <div className="loader-container">
      <span className="loader" />
    </div>
  );
}

export default Loader;
