import scrollDownGif from "../../assets/images/scroll-down.gif";
import "./ReceivedMessagesBadge.css";
import "../../assets/js/ripple/ripple.css";

function ReceivedMessagesBadge(props) {
  const scrollToBottom = () => {
    props.bottomEleRef.current.scrollIntoView({
      behavior: "smooth",
    });
    props.updateReceivedMessageBadgeCount(0);
  };
  return (
    <div
      className={props.count > 0 ? "badge-block show" : "badge-block hide"}
      onClick={scrollToBottom}
      title="Scroll Down"
    >
      {/* <span className="arrow">{">>"}</span> */}
      <img src={scrollDownGif} alt="scroll-down" height="35" />
      <span className="count">{props.count}</span>
    </div>
  );
}

export default ReceivedMessagesBadge;
