import React, { useContext } from "react";
import { send_connection_request } from "../../services/connections";
import { LoaderContext } from "../../context/LoaderContext";
import "./SearchList.css";

function SearchList(props) {
  const { setLoading } = useContext(LoaderContext);

  function sendConnectionRequest(targetId) {
    setLoading(true);
    const data = { target_id: targetId, type: 0 };

    send_connection_request(data)
      .then((res) => {
        setLoading(false);
        props.getSearchedData(props.inputRef.current.value);
      })
      .catch((res) => {
        setLoading(false);
      });
  }

  function showUsers() {
    let users = [];
    for (let i = 0; i < props.searchedUsers.length; i++) {
      users.push(
        <div className="search-content" key={props.searchedUsers[i]._id}>
          <h2 className="name">{props.searchedUsers[i].name}</h2>
          {props.searchedUsers[i].connection_status === 2 ? (
            <span>Connection already exists</span>
          ) : props.searchedUsers[i].connection_status === 1 ? (
            <span>Request already sent</span>
          ) : (
            <button
              className="connect-button"
              onClick={() => sendConnectionRequest(props.searchedUsers[i]._id)}
            >
              Connect
            </button>
          )}
        </div>
      );
    }
    return users;
  }

  return <div className="searchlist-container">{showUsers()}</div>;
}

export default SearchList;
