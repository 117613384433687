export function save_message(data) {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/save_message`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject();
        }
      });
  });
}

export function fetch_message(target_id, page, messageId = "") {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/message/get?receiver_id=` +
        target_id +
        `&page=` +
        page +
        `&message_id=` +
        messageId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return {};
        }
      })
      .then((response) => {
        resolve(response.data);
      });
  });
}
