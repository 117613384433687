import { fetch_notifications } from "../../services/notifications";
import {
  fetchConnectionsFailure,
  fetchConnectionsRequest,
} from "../connections/connectionActions";
import {
  FETCH_NOTIFICATION_FAILURE,
  FETCH_NOTIFICATION_REQUEST,
  FETCH_NOTIFICATION_SUCCESS,
  UPDATE_COUNT,
} from "./notificationTypes";

export const fetchNotificationRequest = () => {
  return {
    type: FETCH_NOTIFICATION_REQUEST,
  };
};

export const fetchNotificationSuccess = (notifications) => {
  return {
    type: FETCH_NOTIFICATION_SUCCESS,
    payload: notifications,
  };
};

export const fetchNotificationFailure = (error) => {
  return {
    type: FETCH_NOTIFICATION_FAILURE,
    error: error,
  };
};

export const fetchNotifications = (manualHit) => {
  return (dispatch) => {
    dispatch(fetchConnectionsRequest());
    fetch_notifications(manualHit)
      .then((res) => {
        dispatch(fetchNotificationSuccess(res));
      })
      .catch((err) => {
        dispatch(fetchConnectionsFailure(err));
      });
  };
};

export const updateCount = (count) => {
  return {
    type: UPDATE_COUNT,
    payload: { count },
  };
};
