import "./NotificationCount.css";

function NotificationCount(props) {
  let classes = "notification-count";
  if (props.showAlert) {
    classes += " notifiction-circle";
  }

  if (props.count === 0) {
    classes += " hidden";
  }

  return <span className={classes}>{props.count}</span>;
}

export default NotificationCount;
