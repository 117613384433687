import { useContext } from "react";
import { delete_connection } from "../../services/connections";
import { useHistory } from "react-router-dom";
import "./DeleteConnectionDialog.css";
import { fetchConnections } from "../../redux";
import { connect } from "react-redux";
import { LoaderContext } from "../../context/LoaderContext";

function DeleteConnectionDialog({ props, fetchConnections }) {
  const history = useHistory();
  const { setLoading } = useContext(LoaderContext);

  const formSubmit = (event) => {
    event.preventDefault();
    const data = {
      id: props.deleteConnection.id,
    };
    setLoading(true);
    delete_connection(data)
      .then((res) => {
        props.updateDeleteConnection(null);
        fetchConnections();
        setLoading(false);
        history.push("/home");
      })
      .catch((err) => {
        setLoading(false);
        props.updateDeleteConnection(null);
      });
  };

  return (
    <div className="dialog-container">
      <div className="dialog-content">
        <p>
          Are you sure you want to delete this connection with{" "}
          {props.deleteConnection.name}?
        </p>
        <form onSubmit={formSubmit}>
          <button
            type="button"
            onClick={() => props.updateDeleteConnection(null)}
            className="cancel"
          >
            No
          </button>
          <button type="submit" className="submit">
            Yes
          </button>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    props: props,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchConnections: () => dispatch(fetchConnections()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteConnectionDialog);
