import { fetch_message } from "../../services/messages";
import {
  APPEND_NEW_MESSAGE,
  FETCH_MESSAGES_FAILURE,
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  UPDATE_MESSAGE_STATE,
  UPDATE_MESSAAGE,
} from "./messageTypes";

import {
  updateTarget,
  moveConnectionAtTop,
} from "../connections/connectionActions";

let loadingMessages = false;

export const fetchMessagesRequest = () => {
  return {
    type: FETCH_MESSAGES_REQUEST,
  };
};

export const fetchMessageSuccess = (messages) => {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    payload: messages,
  };
};

export const fetchMessageFailure = (error) => {
  return {
    type: FETCH_MESSAGES_FAILURE,
    error: error,
  };
};

export const fetchMessages = (target_id, page, messageId = null) => {
  return (dispatch) => {
    if (!loadingMessages) {
      loadingMessages = true;
      dispatch(fetchMessagesRequest());
      fetch_message(target_id, page, messageId)
        .then((res) => {
          res.page = page;
          dispatch(fetchMessageSuccess(res));
          dispatch(updateTarget(res.target, res.connection_id));
          loadingMessages = false;
        })
        .catch((err) => {
          dispatch(fetchMessageFailure(err));
          loadingMessages = false;
        });
    }
  };
};

export const appendNewMessage = (messages, target_id, action_type) => {
  return (dispatch) => {
    dispatch(appendANewMessage(messages, target_id, action_type));
    dispatch(moveConnectionAtTop(target_id));
  };
};

export const appendANewMessage = (messages, target_id, action_type) => {
  return {
    type: APPEND_NEW_MESSAGE,
    payload: { messages, target_id, action_type },
  };
};

export const updateMessageState = (message, target_id) => {
  return {
    type: UPDATE_MESSAGE_STATE,
    payload: { message, target_id },
  };
};

export const updateMessage = (
  message_id,
  unique_id,
  target_id,
  type,
  title
) => {
  return {
    type: UPDATE_MESSAAGE,
    payload: { message_id, unique_id, target_id, type, title },
  };
};
