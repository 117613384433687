import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { UserContext } from "./context/UserContext";
import { LoaderContext } from "./context/LoaderContext";
import { SocketContext, socket } from "./context/Socket";
import Welcome from "../src/container/welcome/welcome";
import Home from "../src/container/home/home";
import Loader from "../src/assets/js/loader/loader";
import { useHistory, useLocation } from "react-router-dom";
import "./App.css";
import { verifyTokenValidity } from "./services/users";
import { NotificationContext } from "./context/NotificationContext";
import NotificationCard from "./components/NotificationCard/NotificationCard";

function App() {
  const [user, setUser] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [notification, setNotification] = useState([]);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      console.log("Notifications are supported");
      Notification.requestPermission();
    }
  }, []);

  useEffect(() => {
    if (!(user._id && user.name)) {
      checkIfTokenIsValid();
    } else {
      if (location.pathname === "/") {
        history.replace("/home");
      }
    }
  }, [history]);

  function checkIfTokenIsValid() {
    verifyTokenValidity()
      .then((res) => {
        setUser({ _id: res.userId, name: res.name });
        if (location.pathname === "/") {
          history.replace("/home");
        }
      })
      .catch((err) => {
        setUser(null);
        history.replace("/");
      });
  }

  return (
    <div className="App">
      <UserContext.Provider value={{ user, setUser }}>
        <LoaderContext.Provider value={{ isLoading, setLoading }}>
          <SocketContext.Provider value={{ socket }}>
            <NotificationContext.Provider
              value={{ notification, setNotification }}
            >
              <Switch>
                <Route exact path="/" component={Welcome}></Route>
                <Route path="/home" component={Home}></Route>
                <Route path="/chat/:personId" component={Home}></Route>
              </Switch>
              {isLoading ? <Loader></Loader> : ""}
              <NotificationCard
                notification={notification}
                setNotification={setNotification}
              />
            </NotificationContext.Provider>
          </SocketContext.Provider>
        </LoaderContext.Provider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
