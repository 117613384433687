export const messagesLoaded = (element, type = 0) => {
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: type === 0 ? "end" : "start",
    });
  }
};

export const messageSent = (element) => {
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
    });
  }
};

export const messagesAppended = (chatScreenElement, element) => {
  const scrollBottomDistance =
    chatScreenElement.scrollHeight -
    chatScreenElement.scrollTop -
    chatScreenElement.clientHeight;
  if (scrollBottomDistance <= 80 && element) {
    element.scrollIntoView({
      behavior: "smooth",
    });
  }
};

export const messagePagination = () => {};

export const messagesStateChange = () => {};
