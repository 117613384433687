import { fetch_connections } from "../../services/connections";
import {
  FETCH_CONNECTIONS_FAILURE,
  FETCH_CONNECTIONS_REQUEST,
  FETCH_CONNECTIONS_SUCCESS,
  APPEND_UNREAD_MESSAGES,
  CLEAR_UNREAD_MESSAGES,
  UPDATE_TARGET,
  MOVE_CONNECTION_AT_TOP,
} from "./connectionTypes";

export const fetchConnectionsRequest = () => {
  return {
    type: FETCH_CONNECTIONS_REQUEST,
  };
};

export const fetchConnectionsSuccess = (connections) => {
  return {
    type: FETCH_CONNECTIONS_SUCCESS,
    payload: connections,
  };
};

export const fetchConnectionsFailure = (error) => {
  return {
    type: FETCH_CONNECTIONS_FAILURE,
    payload: error,
  };
};

export const fetchConnections = () => {
  return (dispatch) => {
    dispatch(fetchConnectionsRequest());
    fetch_connections()
      .then((res) => {
        dispatch(fetchConnectionsSuccess(res));
      })
      .catch((err) => {
        dispatch(fetchConnectionsFailure(err));
      });
  };
};

export const appendUnreadMessages = (messages) => {
  return {
    type: APPEND_UNREAD_MESSAGES,
    payload: { messages },
  };
};

export const clearUnreadMessages = (connection_id) => {
  return {
    type: CLEAR_UNREAD_MESSAGES,
    payload: { connection_id },
  };
};

export const updateTarget = (target, connection_id) => {
  return {
    type: UPDATE_TARGET,
    payload: { target, connection_id },
  };
};

export const moveConnectionAtTop = (target_id) => {
  return {
    type: MOVE_CONNECTION_AT_TOP,
    payload: { target_id },
  };
};
