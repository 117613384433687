export function fetch_notifications(manualHit) {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/get?manual_hit=${
        manualHit ? 1 : 0
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return {}; //handle error
        }
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((response) => {
        reject(response.data);
      });
  });
}
