import { combineReducers } from "redux";
import connectionReducer from "./connections/connectionReducer";
import notificationReducer from "./notifications/notificationReducer";
import messageReducer from "./messages/messageReducer";

const rootReducer = combineReducers({
  connection: connectionReducer,
  notification: notificationReducer,
  message: messageReducer,
});

export default rootReducer;
